import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SHOP_ORIGIN } from '@/services/Configuration'
import { log } from '@/services/Log'

const CANONICAL_QUERY_PARAMS = ['page', 'color']
const shouldIncludeQueryParam = (key: string) => (
  CANONICAL_QUERY_PARAMS.includes(key)
)

const getParamValue = (value: string | string[] | undefined) => (
  typeof value === 'string' ? value : value?.[0] || ''
)

type QueryEntry = [string, string | string[] | undefined]

const getCanonicalLink = (queryEntries: QueryEntry[]) => (path: string) => {
  const url = new URL(`${SHOP_ORIGIN}${path}`.split('?')?.[0])
  queryEntries.forEach(([key, value]) => {
    if (shouldIncludeQueryParam(key)) {
      url.searchParams.set(key, getParamValue(value))
    }
  })
  log('getCanonicalLink', {
    url: url.toString(),
    queryEntries,
    path,
  })
  return url.toString()
}

const LinkCanonical: React.FC<{
  path?: string
}> = ({ path }) => {
  const router = useRouter()
  const canonicalLink = getCanonicalLink(Object.entries(router.query))(path || router.asPath)
  return (
    <Head>
      <link data-testid="canonical-link" rel="canonical" href={canonicalLink} />
    </Head>
  )
}

export default LinkCanonical
